import {
  FURNITURE_DEFAULT_INVENTORY,
  FURNITURE_OTHER_TYPE,
  ITEM_TYPE_BY_TITLE
} from "~/constants/item"

export function findItemTypeByName(name: string | undefined) {
  if (!name || typeof name !== "string") {
    return null
  }

  for (const [title, type] of Object.entries(ITEM_TYPE_BY_TITLE)) {
    if (name.toLowerCase().includes(title.toString().toLowerCase())) {
      return type
    }
  }

  return null
}

export function findItemIconByType(type: number | undefined) {
  if (!type) {
    return FURNITURE_OTHER_TYPE.icon
  }

  return FURNITURE_DEFAULT_INVENTORY[type]
    ? FURNITURE_DEFAULT_INVENTORY[type].icon
    : FURNITURE_OTHER_TYPE.icon
}

export function getCustomItemDimensions(item: Item) {
  return (
    item.original_width +
    " x " +
    item.original_height +
    " x " +
    item.original_depth +
    " " +
    item.dimension_unit
  )
}
