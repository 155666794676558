export default (): BookingInsightsState => ({
  bookingsCopy: null,
  bookingsMap: null,
  bookingsReviews: null,
  currentBookingQuote: null,
  quoteId: null,
  currentBookingQuoteLoading: false,
  error: null,
  postalCode: null
})
