import apiClient from "~/services/ApiService"
import { ApiResource } from "~/scripts/api-resource"

const apiUrl = {
  textToQuote: {
    get: "ng/item-identifier/text-to-quote/invoke"
  }
}

export function identifyItems(query, apiResource?: ApiResource): any | null {
  return apiClient.request({ url: apiUrl.textToQuote.get, data: { query } }, apiResource)
}
