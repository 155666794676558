export const _SET_BOOKINGS_COUNT = "SET_BOOKINGS_COUNT"
export const _SET_BOOKINGS_MAP = "SET_BOOKINGS_MAP"
export const _SET_BOOKINGS_REVIEWS = "SET_BOOKINGS_REVIEWS"
export const _SET_BOOKING_QUOTE = "SET_BOOKING_QUOTE"
export const _SET_POSTAL_CODE = "SET_POSTAL_CODE"
export const _SET_QUOTE_ID = "SET_QUOTE_ID"
export const _SET_BOOKING_QUOTE_LOADING = "SET_BOOKING_QUOTE_LOADING"
export const _SET_ERROR = "SET_ERROR"

export default {
  [_SET_BOOKINGS_COUNT](state: BookingInsightsState, data: AvBookingsCopy) {
    state.bookingsCopy = data
  },
  [_SET_BOOKINGS_MAP](state: BookingInsightsState, data: AvMap) {
    state.bookingsMap = data
  },
  [_SET_BOOKINGS_REVIEWS](state: BookingInsightsState, data: AvReview[]) {
    state.bookingsReviews = data
  },
  [_SET_BOOKING_QUOTE](state: BookingInsightsState, data: BookingQuote) {
    state.currentBookingQuote = data
  },
  [_SET_POSTAL_CODE](state: BookingInsightsState, data: string) {
    state.postalCode = data
  },
  [_SET_QUOTE_ID](state: BookingInsightsState, data: string) {
    state.quoteId = data
  },
  [_SET_BOOKING_QUOTE_LOADING](state: BookingInsightsState, value: boolean) {
    state.currentBookingQuoteLoading = value
  },
  [_SET_ERROR](state: BookingInsightsState, error: string | null) {
    state.error = error
  }
}
