import {identifyItems} from "~/services/public/ItemIdentifier";
export const IDENTIFY_ITEMS_FROM_TITLE = "identifyItemsFromTitle"
export default {
  async [IDENTIFY_ITEMS_FROM_TITLE]({ commit }, title: string) {
    const response = await identifyItems({title:title})

    const items: Array<Item> = response.items.map(itemData => {
      return {
        item_id: itemData.item_id,
        name: itemData.name,
        original_width: itemData.original_width,
        original_depth: itemData.original_depth,
        original_height: itemData.original_height,
        original_weight: itemData.original_weight,
        dimension_unit: itemData.dimension_unit,
        weight_unit: itemData.weight_unit,
        fake_id: itemData.fake_id,
        quantity: itemData.quantity,
        is_admin: itemData.is_admin,
        type: itemData.type
      }
    })
    return items
  }
}
