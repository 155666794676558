
import Vue from "vue"

export default Vue.extend({
  name: "DefaultLayout",

  head() {
    const head = {
      title: "AnyVan",
      script: [] as any[]
    }

    if (this.$config.oneTrustAccountId) {
      Object.assign(head, { __dangerouslyDisableSanitizers: ["innerHTML"] })

      head.script.push(
        {
          innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){
              dataLayer.push(arguments);
            }
            gtag("consent", "default", {"ad_storage": "granted", "analytics_storage": "granted"});`,
        },
        {
          src: "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
          "data-domain-script": this.$config.oneTrustAccountId,
          async: true,
          "data-language": this.$i18n.locale || "en"
        }
      )
    }

    return head
  }
})
