export const GET_BOOKINGS_COPY = "getBookingsCopy"
export const GET_BOOKINGS_MAP = "getBookingsMap"
export const GET_BOOKINGS_REVIEWS = "getBookingsReviews"
export const GET_BOOKING_QUOTE = "getBookingQuote"
export const GET_POSTAL_CODE = "getPostalCode"
export const GET_BOOKING_QUOTE_LOADING = "getCurrentBookingQuoteLoading"
export const GET_ERROR = "getError"

export default {
  [GET_BOOKINGS_COPY]: (state: BookingInsightsState) => state.bookingsCopy,
  [GET_BOOKINGS_MAP]: state => state.bookingsMap,
  [GET_BOOKINGS_REVIEWS]: state => state.bookingsReviews,
  [GET_BOOKING_QUOTE]: state => state.currentBookingQuote,
  [GET_POSTAL_CODE]: state => state.postalCode,
  [GET_BOOKING_QUOTE_LOADING]: state => state.currentBookingQuoteLoading,
  [GET_ERROR]: state => state.error
}
