
import Vue from "vue"

export default Vue.extend({
  name: "ErrorLayout",

  head() {
    return {
      title: "404"
    }
  }
})
