import {
  _SET_PICKUP_ADDRESS,
  _SET_DELIVERY_ADDRESS,
  _SET_PICKUP_PROPERTY_TYPE,
  _SET_DELIVERY_PROPERTY_TYPE,
  _SET_CATEGORY,
  _SET_LISTING,
  _SET_PICKUP_FLOOR,
  _SET_DELIVERY_FLOOR,
  _SET_PICKUP_LIFT_AVAILABLE,
  _SET_DELIVERY_LIFT_AVAILABLE,
  _UPDATE_ITEM,
  _ADD_ITEM,
  _DELETE_ITEM,
  _SET_FIRST_ITEM,
  _DELETE_ITEM_BY_INDEX,
  _CLEAR_ITEMS,
  _SET_ITEMS, _SET_ITEM_IDENTIFIER_STATUS, _SET_ITEM_IDENTIFIER_TEXT
} from "~/store/listing/mutations"
import { GET_FAKE_ID, GET_ITEM } from "~/store/listing/getters"
import { iterationCopy } from "~/scripts/clone"

export const ADD_ITEM = "addItem"
export const UPDATE_ITEM = "updateItem"
export const DELETE_ITEM = "deleteItem"
export const INCREASE_ITEM = "increaseItem"
export const DECREASE_ITEM = "decreaseItem"
export const SET_FIRST_ITEM = "setFirstItem"
export const DELETE_ITEM_BY_INDEX = "deleteItemByIndex"
export const SET_ITEMS = "setItems"
export const SET_ITEM_IDENTIFIER_TEXT = "setItemIdentifierText"
export const SET_ITEM_IDENTIFIER_STATUS = "setItemIdentifierStatus"
export const CLEAR_ITEMS = "clearItems"
export const UPDATE_ITEMS = "updateItems"
export const ADD_ITEM_WITHOUT_REQUEST = "addItemWithoutRequest"
export const UPDATE_ITEM_WITHOUT_REQUEST = "updateItemWithoutRequest"
export const SET_PICKUP_ADDRESS = "setPickupAddress"
export const SET_DELIVERY_ADDRESS = "setDeliveryAddress"
export const SET_PICKUP_PROPERTY_TYPE = "setPickupPropertyType"
export const SET_DELIVERY_PROPERTY_TYPE = "setDeliveryPropertyType"
export const SET_PICKUP_FLOOR = "setPickupFloor"
export const SET_DELIVERY_FLOOR = "setDeliveryFloor"
export const SET_PICKUP_LIFT_AVAILABLE = "setPickupLiftAvailable"
export const SET_DELIVERY_LIFT_AVAILABLE = "setDeliveryLiftAvailable"
export const SET_CATEGORY = "setCategory"
export const SET_LISTING = "setListing"

export default {
  [ADD_ITEM]({ commit, getters }, itemObj: Item) {
    const item: Item = Object.assign({}, itemObj)
    item.fake_id = GET_FAKE_ID(item)
    const alreadyAddedItem: Item = getters[GET_ITEM](item)

    if (typeof alreadyAddedItem !== "undefined") {
      item.quantity = alreadyAddedItem.quantity! + (item.quantity ? item.quantity : 1)
      item.item_id = alreadyAddedItem.item_id!
      commit(_UPDATE_ITEM, item)
    } else {
      if (!item.quantity) {
        item.quantity = 1
      }
      const itemSaved: Item = Object.assign({}, item)
      commit(_ADD_ITEM, itemSaved)
    }

    return item
  },

  [UPDATE_ITEM]({ commit, getters }, itemObj: Item) {
    const item: Item = Object.assign({}, itemObj)
    item.fake_id = GET_FAKE_ID(item)

    const alreadyAddedItem: Item | undefined = getters[GET_ITEM](item)

    if (typeof alreadyAddedItem !== "undefined" && itemObj.fake_id !== item.fake_id) {
      item.quantity = alreadyAddedItem.quantity! + (item.quantity ? item.quantity : 1)
      commit(_UPDATE_ITEM, item)
      commit(_DELETE_ITEM, itemObj)
      return true
    }

    const alreadyAddedItemObj: Item | undefined = getters[GET_ITEM](itemObj)

    if (typeof alreadyAddedItemObj !== "undefined") {
      commit(_UPDATE_ITEM, itemObj)
      return true
    }
  },

  [DELETE_ITEM]({ commit, getters }, itemObj: Item) {
    if (typeof getters[GET_ITEM](itemObj) !== "undefined") {
      commit(_DELETE_ITEM, itemObj)
      return true
    }
  },

  [INCREASE_ITEM]({ commit, getters }, itemObj: Item) {
    if (typeof getters[GET_ITEM](itemObj) !== "undefined") {
      const item: Item = Object.assign({}, itemObj)
      item.quantity!++
      commit(_UPDATE_ITEM, item)
      return true
    }
  },

  [DECREASE_ITEM]({ commit, getters }, itemObj: Item) {
    if (typeof getters[GET_ITEM](itemObj) !== "undefined") {
      if (itemObj.quantity !== undefined && itemObj.quantity > 1) {
        const item: Item = Object.assign({}, itemObj)
        item.quantity!--
        commit(_UPDATE_ITEM, item)
      } else {
        commit(_DELETE_ITEM, itemObj)
        return true
      }
    }
  },

  [SET_FIRST_ITEM]({ commit }, item: Item) {
    commit(_SET_FIRST_ITEM, iterationCopy(item))
  },

  [DELETE_ITEM_BY_INDEX]({ commit }, index: number) {
    commit(_DELETE_ITEM_BY_INDEX, index)
  },

  [CLEAR_ITEMS]({ commit }) {
    commit(_CLEAR_ITEMS)
  },

  [SET_ITEMS]({ commit }, items: Array<Item>) {
    commit(_SET_ITEMS, items)
  },

  [SET_ITEM_IDENTIFIER_TEXT]({ commit }, item_identifier_text: string) {
    commit(_SET_ITEM_IDENTIFIER_TEXT, item_identifier_text)
  },

  [SET_ITEM_IDENTIFIER_STATUS]({ commit }, item_identifier_status: number) {
    commit(_SET_ITEM_IDENTIFIER_STATUS, item_identifier_status)
  },

  [ADD_ITEM_WITHOUT_REQUEST]({ commit }, item: Item) {
    commit(_ADD_ITEM, item)
  },

  [UPDATE_ITEM_WITHOUT_REQUEST]({ commit }, item: Item) {
    commit(_UPDATE_ITEM, item)
  },

  [SET_PICKUP_ADDRESS]({ commit }, address: Address | null) {
    commit(_SET_PICKUP_ADDRESS, address)
  },

  [SET_DELIVERY_ADDRESS]({ commit }, address: Address | null) {
    commit(_SET_DELIVERY_ADDRESS, address)
  },

  [SET_PICKUP_PROPERTY_TYPE]({ commit }, propertyType: string | null) {
    commit(_SET_PICKUP_PROPERTY_TYPE, propertyType)
  },

  [SET_DELIVERY_PROPERTY_TYPE]({ commit }, propertyType: string | null) {
    commit(_SET_DELIVERY_PROPERTY_TYPE, propertyType)
  },

  [SET_PICKUP_FLOOR]({ commit }, floor: string | null) {
    commit(_SET_PICKUP_FLOOR, floor)
  },

  [SET_DELIVERY_FLOOR]({ commit }, floor: string | null) {
    commit(_SET_DELIVERY_FLOOR, floor)
  },

  [SET_PICKUP_LIFT_AVAILABLE]({ commit }, lift: LiftAvailable) {
    commit(_SET_PICKUP_LIFT_AVAILABLE, lift)
  },

  [SET_DELIVERY_LIFT_AVAILABLE]({ commit }, lift: LiftAvailable) {
    commit(_SET_DELIVERY_LIFT_AVAILABLE, lift)
  },

  [SET_CATEGORY]({ commit }, categoryId: string | null) {
    commit(_SET_CATEGORY, categoryId)
  },

  [SET_LISTING]({ commit }, listing: Listing) {
    commit(_SET_LISTING, listing)
  }
}
