import {
  _SET_PROPERTY_TYPES,
  _SET_PROPERTY_TYPES_MAIN,
  _SET_PROPERTY_TYPES_HOUSE,
  _SET_PROPERTY_TYPES_FLAT,
  _SET_FLOORS
} from "~/store/property/mutations"

export const ADD_PROPERTY_TYPES = "addPropertyTypes"
export const ADD_PROPERTY_TYPES_HOUSE = "addPropertyTypesHouse"
export const ADD_PROPERTY_TYPES_FLAT = "addPropertyTypesFlat"
export const ADD_FLOORS = "addFloors"

export default {
  [ADD_PROPERTY_TYPES]({ commit }, propertyTypes: any[]) {
    const house = propertyTypes.find(item => item.identifier === "house")
    const flat = propertyTypes.find(item => item.identifier === "flat")
    const other = propertyTypes.find(item => item.identifier === "other")
    commit(_SET_PROPERTY_TYPES, [...house.values, ...flat.values, ...other.values])

    const propertyTypesMapped = ["House", "Flat"].map(type => {
      const item = propertyTypes.find(item => item.identifier === type.toLowerCase())
      const mapped: any = { name: item.label, value: type }
      if (item.identifier === "flat") {
        mapped.propertyWithLevels = true
      }
      return mapped
    })
    commit(_SET_PROPERTY_TYPES_MAIN, [...propertyTypesMapped, ...other.values])
  },

  [ADD_PROPERTY_TYPES_HOUSE]({ commit }, propertyTypes: any[]) {
    commit(_SET_PROPERTY_TYPES_HOUSE, propertyTypes)
  },

  [ADD_PROPERTY_TYPES_FLAT]({ commit }, propertyTypes: any[]) {
    commit(_SET_PROPERTY_TYPES_FLAT, propertyTypes)
  },

  [ADD_FLOORS]({ commit }, floors: any[]) {
    commit(_SET_FLOORS, floors)
  }
}
