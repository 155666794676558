export interface Property {
  propertyTypes: any[] | null
  propertyTypesMain: any[] | null
  propertyTypesHouse: any[] | null
  propertyTypesFlat: any[] | null
  floors: any[] | null
}
export default (): Property => ({
  propertyTypes: null,
  propertyTypesMain: null,
  propertyTypesHouse: null,
  propertyTypesFlat: null,
  floors: null
})
